<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
    <!-- 预览 -->
    <el-dialog
      append-to-body
      class="previewDialog"
      :close-on-click-modal="false"
      size="tiny"
      title="查看大图"
      top="10vh"
      :visible.sync="dialogVisible"
      width="36%"
    >
      <div class="imgeDialog">
        <img alt="" class="imgPreviewDialog" :src="currentImageUrl" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryDataList } from "@/project/eztree/baseApi/TreeItemWaterApi";
const selectOptions = [{ label: "首页banner", value: "index_banner" }];
const statusOptions = [
  { label: "下架", value: "0" },
  { label: "上架", value: "1" },
];
export default {
  name: "TreeItemImage",
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      currentImageUrl: "",
      selectOptions,
      statusOptions,
      queryForm: {},
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "name",
          label: "名称",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "名称",
          prop: "name",
        },
        {
          label: "图片",
          key: "water_link",
          align: "center",
          render: function (h, params) {
            return h("div", [
              h("img", {
                attrs: {
                  style: "height:100px;",
                  src: params.row.water_link,
                  alt: "图片走丢了",
                },
              }),
            ]);
          },
        },
        {
          label: "创建时间",
          prop: "created_at",
        },
        {
          label: "修改时间",
          prop: "updated_at",
        },
        {
          label: "操作",
          fixed: "right",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {},
              },
              [
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.dialogVisible = true;
                        this.currentImageUrl = params.row.water_link;
                      },
                    },
                  },
                  "查看大图"
                ),
                // h('el-button', {
                //   attrs: {
                //     type: "text",
                //     params: params.row,
                //   },
                //   on: {
                //     ...this.$listeners,
                //     click: () => {
                //       this.$refs['edit'].showEdit(params.row, 'edit')
                //     }
                //   },
                // }, '编辑'),
                // h('el-button', {
                //   attrs: {
                //     type: "text",
                //     params: params.row,
                //   },
                //   on: {
                //     ...this.$listeners,
                //     click: () => {
                //       this.$refs['edit'].showEdit(params.row, 'delete')
                //     }
                //   },
                // }, '删除'),
              ]
            );
          },
        },
      ];
      return columns;
    },
  },
  mounted() {},
  methods: {
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      queryDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
  },
};
</script>
