import request from '@/utils/request'
// 首页列表
export function queryDataList (data) {
  return request({
    url: '/admin/water-list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: '/admin/water-save',
    method: 'post',
    data,
  })
}
// 删除
export function delDataItem (data) {
  return request({
    url: '/admin/water-del',
    method: 'post',
    data,
  })
}

