var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c("nxp-search", {
        attrs: { "config-list": _vm.searchConfigList },
        on: { clickSearch: _vm.clickSearch, resetForm: _vm.resetForm },
      }),
      _c("select-table", {
        attrs: {
          accessories: _vm.nxpTabelAccessories,
          "only-key-id": "id",
          "table-columns": _vm.tableColumns,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "w-theight": 320,
        },
        on: { fetchData: _vm.fetchData },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "previewDialog",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            size: "tiny",
            title: "查看大图",
            top: "10vh",
            visible: _vm.dialogVisible,
            width: "36%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "imgeDialog" }, [
            _c("img", {
              staticClass: "imgPreviewDialog",
              attrs: { alt: "", src: _vm.currentImageUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }